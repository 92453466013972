import React from 'react';
import {useTranslation} from "react-i18next";
import Link from 'next/link';

function Footer(props) {
    const {t, i18n} = useTranslation('common');

    return (
        <div className="Footer">
            <Link href={'/imprint'}>
                <div className="ImprintLink NextLink">
                    <div>
                        Imprint
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default Footer;
