import '../styles/App.css';
import '../styles/Navigation.css';
import '../styles/Footer.css';

import Footer from "../components/footer";
import Navigation from "../components/navigation/Navigation";
import { QueryClient, QueryClientProvider } from 'react-query'
import React from "react";

const queryClient = new QueryClient();

const dev = process.env.NODE_ENV !== 'production';

function MyApp ({ Component, pageProps }) {
    return (
        <div>
              <QueryClientProvider client={queryClient}>
                <div className="App">
                    <Navigation {...pageProps} />
                    <Component {...pageProps} />
                    <Footer {...pageProps} />
                </div>
              </QueryClientProvider>
        </div>
    );
}

export default MyApp
