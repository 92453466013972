import React from 'react';
import Link from 'next/link';

const Navigation = React.memo(props => {
    return (
        <div className={"Navigation"}>
            <Link href={'/'}>
                <div className="Logo">
                    <div><img src="/nfthive-logo.svg" alt="Worldwide Asset Explorer"/></div>
                </div>
            </Link>
            <div className="Links">
                <Link  href={'/projects'} className={"NextLink NavigationItem"}>
                    Projects
                </Link>
                <Link  href={'/team'} className={"NextLink NavigationItem"}>
                    Team
                </Link>
                <div className={"NavigationItem"}>
                    <a href={'/bp.json'}>bp.json</a>
                </div>
            </div>
        </div>
    );
});

export default Navigation;
